import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then( m => m.ReportsPageModule)
  },
  // {
  //   path: 'report-modal',
  //   loadChildren: () => import('./pages/report-modal/report-modal.module').then( m => m.ReportModalPageModule)
  // },
  {
    path: 'addition-reports',
    loadChildren: () => import('./pages/addition-reports/addition-reports.module').then( m => m.AdditionReportsPageModule)
  },
  {
    path: 'searchfilter',
    loadChildren: () => import('./pages/searchfilter/searchfilter.module').then( m => m.SearchfilterPageModule)
  },
  // {
  //   path: 'testing',
  //   loadChildren: () => import('./pages/testing/testing.module').then( m => m.TestingPageModule)
  // },
  
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
