import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  token;
  url1: string;
  staffid: string;
  staff: string;
  // endpoint = 'http://54.169.2.143:8874';
//  endpoint = 'http://devapps.ml:8874';
 endpoint = 'https://aquarius98.com:8874';
  // endpoint = 'http://localhost:8874';

  //  token ="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Imhhc290ZWNrQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiMDJoYXNvdGVjaCIsImlhdCI6MTYwMjg1MzA2OSwiZXhwIjoxNjAyOTM5NDY5fQ.-5OOshRI36dQiIFSXhUpz7fIkQikP07J9qyk98H0ZFw"
  constructor(private http: HttpClient, private route: Router, private toastCtrl: ToastController) { }


  reportlist() {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }
    // 13.212.22.62:8874
    const url = this.endpoint+'/api/report/list'
    return this.http.get<any>(url, { headers })
  }
  file([imageBlob], imageName) {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    console.log([imageBlob], imageName)
    return new File([imageBlob], imageName, { type: 'image/jpeg' });

  }
  postreport(body) {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }
    const body1 = body
    console.log(body)
    // https://aquarius98.com:8874/api/report/add
    const url = this.endpoint+'/api/report/add'
    return this.http.post<any>(url, body1, { headers })
  }
  clientlist() {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }

    const url = this.endpoint+'/api/client/list'
    return this.http.get<any>(url, { headers })
  }
  categorylist() {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }

    const url = this.endpoint+'/api/category/list'
    return this.http.get<any>(url, { headers })
  }
  checklist() {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }

    const url = this.endpoint+'/api/checklist/list'
    return this.http.get<any>(url, { headers })
  }
  search(cl, cal, fd, td,count,pageindex) {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    console.log("inside api")
    this.token = localStorage.getItem('token')
    this.staff = localStorage.getItem('staffname')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }
    if (cl != '' && cal == '') {
      this.url1 = this.endpoint+'/api/report/search?staffname=' + this.staff +'&fromDate=' + fd + '&toDate=' + td + '&client=' + cl + '&count='+count+'&page='+pageindex+'&sortBy=created_at&OrderBy=desc'
      // this.url1 = 'http://15.206.94.79:8874/api/report/search?staffname=' + this.staff + '&client=' + cl +'&sortBy=created_at&OrderBy=desc'
      console.log("only client")


    }
    if (cal != '' && cl == '' ) {
      this.url1 = this.endpoint+'/api/report/search?staffname=' + this.staff +'&fromDate=' + fd + '&toDate=' + td + '&category=' + cal + '&count='+count+'&page='+pageindex+'&&sortBy=created_at&OrderBy=desc'
      console.log("only catgry")

    }
    if (cl != '' && cal != '' ) {
      this.url1 = this.endpoint+'/api/report/search?staffname=' + this.staff +'&client=' + cl + '&category=' + cal + '&count='+count+'&page='+pageindex+'&&sortBy=created_at&OrderBy=desc'
      console.log("both client and cat")

    }
    if (cl != '' && cal != '' && fd != '' && td != '') {
      this.url1 = this.endpoint+'/api/report/search?staffname=' + this.staff +'&fromDate=' + fd + '&toDate=' + td + '&client=' + cl + '&category=' + cal + '&count='+count+'&page='+pageindex+'&&sortBy=created_at&OrderBy=desc'
      console.log("all")

    }
    // if (cl == '' && cal != '' && fd != '' && td != '') {
    //   this.url1 = 'http://15.206.94.79:8874/api/report/search?fromDate=' + fd + '&toDate=' + td + '&category=' + cal + ''
    //   console.log("cat and date")

    // }
    // if (cl != '' && cal == '' && fd != '' && td != '') {
    //   this.url1 = 'http://15.206.94.79:8874/api/report/search?fromDate=' + fd + '&toDate=' + td + '&client=' + cl + ''
    //   console.log("client and date")

    // }

    if (cl == '' && cal == '' && fd != '' && td != '') {
      this.url1 = this.endpoint+'/api/report/search?staffname=' + this.staff +'&fromDate=' + fd + '&toDate=' + td + '&count='+count+'&page='+pageindex+'&&sortBy=created_at&OrderBy=desc'
      console.log("only date")
      console.log(fd)

    }

    // const url = 'http://13.233.111.218:8874/api/report/search?fromDate='+fd+'&toDate='+td+'&client='+cl+'&category='+cal+''

    // const url = 'http://13.233.111.218:8874/api/report/search?client='+cl+''
    return this.http.get<any>(this.url1, { headers })
  }
  infinite(count, page) {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    this.staffid = localStorage.getItem('staffid')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }
    // 'http://15.206.94.79:8874/api/report/pagination?count='+count+'&page='+page+'&sortBy=created_at&OrderBy=desc'
    const url = this.endpoint+'/api/staff/report/list?staff_ref_id='+this.staffid+'&count='+count+'&page='+page+'&sortBy=created_at&OrderBy=desc'
    return this.http.get<any>(url, { headers })
  }
  uploadFile(formData) {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    return this.http.post('https://example.com/upload.php', formData);
  }
  checklistcate(body){
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }
    const body1 = body
    console.log(body)
    const url = this.endpoint+'/api/category/checklist/list'
    return this.http.post<any>(url, body1, { headers })

  }
  email(id){
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }

    const url = this.endpoint+'/api/report/listById/'+id+''
    return this.http.get<any>(url, { headers })
  }
  login(body) {
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    const headers = {
      'Content-Type': 'application/json'
    }
    const body1 = body
    console.log(body)
    // http://65.0.100.32:8874/
    const url = this.endpoint+'/api/staff/login'
    this.http.post<any>(url, body1, { headers }).subscribe(async data => {
      localStorage.setItem("token", data.token);
      localStorage.setItem("staffname", data.staffname);
      localStorage.setItem("logged","login")
      localStorage.setItem("staffid",data.staffid)
      localStorage.setItem("adminemail",data.adminemail)
      localStorage.setItem("staffemail",data.staffemail)
      console.log(localStorage.getItem('staffname'))

      // this.route.navigateByUrl('/tabs/homee');
      // this.route.navigate(['/tabs/homee']);
      window.location.reload();



    }, async err => {
      console.log(err)
      const toast = await this.toastCtrl.create({
        message: 'Login unsuccessfull',
        duration: 3000,
      });
      toast.present();
    })

  }
  test(){
    localStorage.setItem("endpoint_mobile_fsr_pwa",this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }
    // http://8c3fefad4be3.ngrok.io/api/report/search?fromDate=2021-02-24&toDate=2021-02-28&client=test%20client&category=test%20category&staffname=tester&count=5&page=1&sortBy=created_at&OrderBy=desc
    // http://8c3fefad4be3.ngrok.io/api/report/search?fromDate=2021-02-27&toDate=2021-02-27&client=test%20client&category=test%20category&staffname=tester&count=5&page=1&sortBy=created_at&OrderBy=desc
    // /api/report/excel/searchall
   return this.http.get<any>(this.endpoint+'/api/report/excel/searchall?fromDate=2021-02-27&toDate=2021-02-27&client=test%20client&category=test%20category&staffname=test&count=5&page=1&sortBy=created_at&OrderBy=desc',{headers})
  }
  locationlist(data) {
    localStorage.setItem("endpoint_fsr_admin", this.endpoint)

    this.token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${this.token}`
    }
    const url = this.endpoint + '/api/location/find?count='+data.count+'&client='+data?.client+'&page='+data.page+'&deleted='+data.deleted+'&populate='+data.populate+'&OrderBy="desc"'
    return this.http.get<any>(url, { headers })
  }
  locationAll(){


        this.token = localStorage.getItem('token')
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const url = this.endpoint + '/api/location/find?populate='+null+'&OrderBy="desc"'
        return this.http.get<any>(url, { headers })
  }
}
